import React from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'
import Button from '../../components/CustomButtons/Button.js'
import OneStringModal from '../DataAssure/OneStringModal.js'
import CustomConfirmAlert from 'components/CustomConfirmAlert/CustomConfirmAlert.js'
import Card from 'components/Card/Card.js'

// @material-ui/icons
import Info from '@material-ui/icons/Info'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CancelIcon from '@material-ui/icons/Cancel'
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded'
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined'

import { ServiceType } from 'utils/Constants'

// style
import style from 'assets/jss/material-dashboard-pro-react/views/Apps/widgetFormStyle.js'

class AppMainMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = props.initialState
    this.state.localConfis = {
      showModal: false,
      showDeleteConfirm: false,
      keyToDelete: ''
    }
  }
  sendState() {
    return this.state
  }

  onFieldChange(field, value, mapping = false) {
    let stateCopy = this.state
    if (mapping) stateCopy.mapping[field] = value
    else stateCopy[field] = value

    this.setState(stateCopy)
  }

  onSwitchFieldChange(field, value, id) {
    let stateCopy = this.state
    stateCopy.mapping.switchChildren[id][field] = value

    this.setState(stateCopy)
  }
  checkConnection() {
    this.setState({ aribaConnectedState: true })
  }

  openModal = () =>
    this.setState({
      localConfis: { ...this.state.localConfis, showModal: true }
    })
  closeModal = () =>
    this.setState({
      localConfis: { ...this.state.localConfis, showModal: false }
    })

  openDeleteConfirm = key => {
    this.setState({
      localConfis: {
        ...this.state.localConfis,
        showDeleteConfirm: true,
        keyToDelete: key
      }
    })
  }

  closeDeleteConfirm = () => {
    this.setState({
      localConfis: { ...this.state.localConfis, showDeleteConfirm: false }
    })
  }

  getDeleteConfirm() {
    let modal = ''
    const { classes } = this.props
    modal = (
      <CustomConfirmAlert
        message={this.state.deleteModalMessage}
        title={'Delete'}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        onCancel={() => this.closeDeleteConfirm()}
        onConfirm={() => {
          this.state.onDeleteConfirm(this.state.localConfis.keyToDelete)
          this.closeDeleteConfirm()
        }}
      ></CustomConfirmAlert>
    )
    return modal
  }

  getModal() {
    let modal = ''
    //const { classes } = this.props
    modal = (
      <OneStringModal
        title={this.state.modalTitle}
        initValue={''}
        //confirmBtnCssClass={classes.button + ' ' + classes.success}
        //cancelBtnCssClass={classes.button + ' ' + classes.danger}
        onCancel={() => this.closeModal()}
        onConfirm={newUniqueName => {
          this.state.onAddButtonClick(newUniqueName)
          this.closeModal()
        }}
      ></OneStringModal>
    )
    return modal
  }

  render() {
    const { classes } = this.props
    if (!this.state.mapping) {
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            mapping prop was not sent
          </GridItem>
        </GridContainer>
      )
    }
    return (
      <GridContainer justify="center">
        {this.state.mapping.documentationPath ? (
          <GridItem xs={12} sm={12} md={12}>
            <Button
              simple
              className={classes.documentation}
              href={this.state.mapping.documentationPath}
              target="_blank"
            >
              <Info className={classes.icons} />
              Download Documentation
            </Button>
          </GridItem>
        ) : (
          ''
        )}
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>{this.state.mapping.title}</h4>
        </GridItem>
        <GridItem xs={12}>{this.state.mapping.topChildren}</GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormControlLabel
            classes={{
              label: classes.label
            }}
            label={
              this.state.mapping && this.state.mapping.mainSwitchText
                ? this.state.mapping.mainSwitchText
                : 'Enable'
            }
            labelPlacement="start"
            control={
              <Switch
                checked={this.state.mapping.enabled}
                onChange={event => {
                  this.state.mapping.mainSwitchOnChanged(event, this.state)
                  this.onFieldChange('enabled', event.target.checked, true)
                }}
                value={this.state.mapping.enabled}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar
                }}
              />
            }
          />
        </GridItem>
        <GridItem
          xs={12}
          style={
            !this.state.mapping.enabled ? { backgroundColor: 'rgba(0, 0, 0, 0.03)' } : { '': '' }
          }
        >
          <GridItem xs={12} sm={12}>
            <GridContainer className={classes.row}>
              <GridItem xs={12} sm={12}>
                <h5 className={classes.sectionTitle}>{this.state.mapping.applicationTitle}</h5>
              </GridItem>
            </GridContainer>

            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem xs={12} sm={3}>
                  <CustomInput
                    success={this.state.envState === 'success'}
                    error={this.state.envState === 'error'}
                    labelText={
                      <span>
                        Application Name
                        <small>{this.state.envState === 'error' ? '(required)' : '*'}</small>
                      </span>
                    }
                    id="env"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: this.state.displayMode === 'edit' || !this.state.mapping.enabled,
                      onChange: event => {
                        let value = event.target.value
                        if (value) value = value.replace(' ', '_')
                        else value = ''
                        this.onFieldChange('env', value)
                      },
                      value: this.state.appName ? this.state.appName : this.state.env
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                      Environment Type
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select,
                        disabled: classes.disabled
                      }}
                      value={this.state.type}
                      onChange={e => this.onFieldChange('type', e.target.value)}
                      inputProps={{
                        disabled: !this.state.mapping.enabled,
                        name: 'type',
                        id: 'type'
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                        value={'Test'}
                      >
                        Test
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                        value={'Production'}
                      >
                        Production
                      </MenuItem>

                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                        value={'Demo'}
                      >
                        Demo
                      </MenuItem>
                      <div>
                        <div>
                          <div></div>
                        </div>
                      </div>
                    </Select>
                  </FormControl>
                </GridItem>
                {this.state.mapping.configureService ? (
                  <GridItem xs={12} sm={3}>
                    <FormControl fullWidth className={classes.selectFormControl}>
                      <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                        Service
                      </InputLabel>
                      <Select
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select, disabled: classes.disabled }}
                        value={this.state.service}
                        onChange={e => {
                          this.onFieldChange('service', e.target.value)
                          if (this.state.mapping.setService) {
                            this.state.mapping.setService(e.target.value)
                          }
                        }}
                      >
                        {Object.entries(ServiceType)
                          .filter(
                            ([key, value]) =>
                              !this.state.mapping.configureServiceExclusions?.includes(value)
                          )
                          .map(([key, value]) => (
                            <MenuItem
                              key={key}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              style={{ overflowX: 'auto', textOverflow: 'ellipsis' }}
                              value={key}
                            >
                              {value}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                ) : this.state.mapping.hideService ? (
                  ''
                ) : (
                  <GridItem xs={12} sm={3}>
                    <div
                      style={{
                        textAlign: 'center',
                        paddingTop: '15%',
                        fontSize: 'large'
                      }}
                    >
                      Service: {ServiceType[this.state.service]}
                    </div>
                  </GridItem>
                )}
                {this.state.showAddButton ? (
                  <GridItem xs={12} sm={3}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                      style={{ alignItems: 'center' }}
                    >
                      <Button
                        disabled={!this.state.mapping.enabled}
                        simple
                        onClick={() => this.openModal()}
                        className={classes.documentation}
                        color={'behance'}
                      >
                        <AddBoxRoundedIcon className={classes.icons} />
                        {this.state.addButtonText}
                      </Button>
                    </FormControl>
                  </GridItem>
                ) : (
                  ''
                )}
                {this.state.mapping.configureRealm ? (
                  <GridItem xs={12} sm={3}>
                    <GridItem xs={12}>
                      <CustomInput
                        disabled={!this.state.mapping.enabled}
                        success={this.state.realmState === 'success'}
                        error={this.state.realmState === 'error'}
                        labelText={
                          <span>
                            Realm
                            <small>{this.state.realmState === 'error' ? '(required)' : '*'}</small>
                          </span>
                        }
                        id="realm"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: event => {
                            let newState = this.state
                            newState.realm = event.target.value
                            this.setState(newState)
                          },
                          value: this.state.realm
                        }}
                      />
                    </GridItem>
                  </GridItem>
                ) : (
                  ''
                )}
                {this.state.mapping.configureRegion &&
                this.state.mapping.regionOptions &&
                this.state.mapping.regionOptions.length > 0 ? (
                  <GridItem xs={12} sm={3}>
                    <GridItem xs={12}>
                      <FormControl fullWidth className={classes.selectFormControl}>
                        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
                          Region
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select,
                            disabled: classes.disabled
                          }}
                          multiple={false}
                          value={this.state.region}
                          onChange={e => {
                            /*  this.onMappingFieldChange(
                              'value',
                              e.target.value,
                              id
                            )*/
                            let newState = this.state
                            newState.region = e.target.value
                            this.setState(newState)
                          }}
                        >
                          {this.state.mapping.regionOptions.map((element, count) => {
                            let label
                            let value
                            if (typeof element === 'object') {
                              if (element.label !== undefined) label = element.label
                              if (element.value !== undefined) value = element.value
                            } else {
                              value = element
                              label = element
                            }
                            if (value !== undefined && label !== undefined)
                              return (
                                <MenuItem
                                  key={count}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  style={{
                                    overflowX: 'auto',
                                    textOverflow: 'ellipsis',
                                    textAlign: 'center'
                                  }}
                                  value={value}
                                >
                                  {label}
                                </MenuItem>
                              )
                            else return ''
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridItem>
                ) : (
                  ''
                )}
              </GridContainer>
            </GridItem>
          </GridItem>
          <GridItem xs={12} sm={12}>
            <h5 className={classes.sectionTitle}>{this.state.mapping.switchChildrenTitle}</h5>
            <GridItem xs={12} sm={12}>
              {this.state.mapping.switchChildren ? (
                Object.keys(this.state.mapping.switchChildren).map((id, key) => {
                  let child = this.state.mapping.switchChildren[id]
                  return (
                    <GridContainer key={key} className={classes.appRow}>
                      <GridItem xs={12} sm={child.isCustomValidation ? 6 : 9}>
                        <GridContainer>
                          <div className={classes.block}>
                            <FormControlLabel
                              label={
                                <div style={{ marginLeft: '20px' }}>
                                  <strong>{child.title}: </strong>
                                  {child.description}
                                </div>
                              }
                              control={
                                !child.noShowSwitch ? (
                                  <Switch
                                    disabled={!this.state.mapping.enabled}
                                    checked={
                                      child.enabled
                                        ? child.enabled && this.state.mapping.enabled
                                        : false
                                    }
                                    onChange={event =>
                                      this.onSwitchFieldChange('enabled', event.target.checked, id)
                                    }
                                    value={'switch' + id}
                                    classes={{
                                      switchBase: classes.switchBase,
                                      checked: classes.switchChecked,
                                      thumb: classes.switchIcon,
                                      track: classes.switchBar
                                    }}
                                  />
                                ) : (
                                  <div style={{ width: '10px' }}></div>
                                )
                              }
                              classes={{
                                label: classes.label
                              }}
                            />
                          </div>
                        </GridContainer>
                      </GridItem>
                      {this.state.displayMode === 'edit' && !this.state.hideConfigure ? (
                        <GridItem xs={child.isCustomValidation ? 6 : 12} sm={3}>
                          <Button
                            disabled={!this.state.mapping.enabled}
                            color={child.configured ? 'behance' : 'danger'}
                            simple
                            className={classes.documentation}
                            onClick={() => {
                              if (this.state.mapping.enabled)
                                child.configurationOnClick(id, this.state)
                            }}
                          >
                            {child.configured ? (
                              <CheckBoxIcon className={classes.icons} />
                            ) : (
                              <CancelIcon className={classes.icons} />
                            )}
                            {child.configured
                              ? child.optionLabel
                                ? child.optionLabel
                                : 'Configured'
                              : 'Not Configured'}
                          </Button>
                        </GridItem>
                      ) : null}
                      {this.state.displayMode === 'edit' && child.isCustomValidation ? (
                        <GridItem xs={6} sm={3}>
                          <Button
                            color={'danger'}
                            simple
                            className={classes.documentation}
                            onClick={() => {
                              this.openDeleteConfirm(id)
                              this.state.setConfigurationDetails('delete_' + id)
                            }}
                          >
                            <DeleteForeverOutlinedIcon className={classes.icon} />
                            {this.state.deleteButtonText}
                          </Button>
                        </GridItem>
                      ) : (
                        ''
                      )}
                    </GridContainer>
                  )
                })
              ) : (
                <div></div>
              )}
            </GridItem>{' '}
          </GridItem>
          <GridItem xs={12} sm={12}>
            <h5 className={classes.sectionTitle}>{this.state.mapping.additionalChildrenTitle}</h5>
            <GridItem xs={12} sm={12}>
              {this.state.mapping.additionalConfigurationsChildren ? (
                Object.keys(this.state.mapping.additionalConfigurationsChildren).map((id, key) => {
                  let child = this.state.mapping.additionalConfigurationsChildren[id]
                  return (
                    <GridContainer key={key} className={classes.appRow}>
                      <GridItem xs={12} sm={child.isCustomValidation ? 6 : 9}>
                        <GridContainer>
                          <div className={classes.block}>
                            <FormControlLabel
                              label={
                                <div style={{ marginLeft: '20px' }}>
                                  <strong>{child.title}: </strong>
                                  {child.description}
                                </div>
                              }
                              control={
                                !child.noShowSwitch ? (
                                  <Switch
                                    disabled={!this.state.mapping.enabled}
                                    checked={
                                      child.enabled
                                        ? child.enabled && this.state.mapping.enabled
                                        : false
                                    }
                                    onChange={event =>
                                      this.onSwitchFieldChange('enabled', event.target.checked, id)
                                    }
                                    value={'switch' + id}
                                    classes={{
                                      switchBase: classes.switchBase,
                                      checked: classes.switchChecked,
                                      thumb: classes.switchIcon,
                                      track: classes.switchBar
                                    }}
                                  />
                                ) : (
                                  <div style={{ width: '10px' }}></div>
                                )
                              }
                              classes={{
                                label: classes.label
                              }}
                            />
                          </div>
                        </GridContainer>
                      </GridItem>
                      {this.state.displayMode === 'edit' && !this.state.hideConfigure ? (
                        <GridItem xs={child.isCustomValidation ? 6 : 12} sm={3}>
                          <Button
                            disabled={!this.state.mapping.enabled}
                            color={child.configured ? 'behance' : 'danger'}
                            simple
                            className={classes.documentation}
                            onClick={() => {
                              if (this.state.mapping.enabled)
                                child.configurationOnClick(id, this.state)
                            }}
                          >
                            {child.configured ? (
                              <CheckBoxIcon className={classes.icons} />
                            ) : (
                              <CancelIcon className={classes.icons} />
                            )}
                            {child.configured
                              ? child.optionLabel
                                ? child.optionLabel
                                : 'Configured'
                              : 'Not Configured'}
                          </Button>
                        </GridItem>
                      ) : null}
                      {this.state.displayMode === 'edit' && child.isCustomValidation ? (
                        <GridItem xs={6} sm={3}>
                          <Button
                            color={'danger'}
                            simple
                            className={classes.documentation}
                            onClick={() => {
                              this.openDeleteConfirm(id)
                              this.state.setConfigurationDetails('delete_' + id)
                            }}
                          >
                            <DeleteForeverOutlinedIcon className={classes.icon} />
                            {this.state.deleteButtonText}
                          </Button>
                        </GridItem>
                      ) : (
                        ''
                      )}
                    </GridContainer>
                  )
                })
              ) : (
                <div></div>
              )}
            </GridItem>{' '}
          </GridItem>
        </GridItem>
        <Card>
          <GridContainer spacing={4}>
            <GridItem xs={12}>{this.state.localConfis.showModal ? this.getModal() : ''}</GridItem>
            <GridItem xs={12}>
              {this.state.localConfis.showDeleteConfirm ? this.getDeleteConfirm() : ''}
            </GridItem>
          </GridContainer>
        </Card>
      </GridContainer>
    )
  }
}

AppMainMenu.propTypes = {
  classes: PropTypes.object
}

export default withStyles(style)(AppMainMenu)
